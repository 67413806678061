/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Col,
  Row,
  CardBody,
  Card,
  Container,
  CardTitle,
} from "reactstrap";
import { isConstructorDeclaration } from "typescript";
// core components

function PresentationHeader2(props) {

  return (
    <>
    <Container fluid>
      {/* <div className="page-header page-header-small" style={{height: "500px"}}> */}
          <Row>
            <Col className="px-0" md="8">
              <Card
                className="card-fashion page-header-image"
                style={{
                  backgroundSize: "100% 100%",
                  backgroundImage:
                    "url(" + require("assets/img/Notaria/fondoEncabezado.jpg") + ")",
                }}
              >
                <CardBody>
                  <CardTitle className="text-left" tag="div">
                  <div className="rellax-text-container rellax-text">
                    <h1 className="title" data-rellax-speed="-1" 
                        style={{marginTop: "-300px", 
                                fontSize: "500%", 
                                color : "#bd0b0ed8"
                                // background: "linear-gradient(180deg,#fff 35%,#75a6c3)",
                                // webkitBackgroundClip: "text",
                                // WebkitTextFillColor: "transparent"
                                }}>
                      Notaría 2 de Floridablanca
                    </h1>
                  </div>
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col className="px-0" md="4">
              <Card className="card-fashion arrow-left">
                <CardTitle tag="div">
                  <h2 className="text-center title">
                    <b style={{color : "#bd0b0ed8"}}>{props.titulo}</b><br></br>
                  </h2>
                </CardTitle>
              </Card>
            </Col>
          </Row>
        {/* </div> */}
      </Container>
    </>
  );
}

export default PresentationHeader2;
