/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer" data-background-color="black">
        <Container>
          <div className="copyright float-left">
            <a href="/TerminosCondiciones">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Términos y condiciones</h6>
            </a>
            <a href="/PoliticaTratamientosDatos">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Política de Tratamiento de Datos Personales</h6>
            </a>
            <a href="/PoliticaDerechosAutor">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Politícas de derechos de autor</h6>
            </a>
            <a href="/CertificadoAccesibilidad">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Certificado de Accesibilidad</h6>
            </a>
            <a href="/PoliticaDerechosWeb">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Políticas de Privacidad web</h6>
            </a>
            <a href="/MapaSitio">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Mapa del Sitio</h6>
            </a>
            <a href="/#contacto">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Canales de atencion</h6>
            </a>
            <a href="/#contacto">
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Teléfono: 57(7)6386227 Fax: 6848975</h6>
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Notificaciones Judiciales: segundafloridablanca@supernotariado.gov.co</h6>
              <h6 className="item-footer-color textot" style={{ textAlign: 'left', cursor: "pointer" }}>Dirección: Cra. 26 # 30-36, Floridablanca, Santander</h6>
            </a>
            <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
              <img
                alt="Logo de Instagram"
                src={require("assets/img/Notaria/instagram.jpg")} style={{ height: '40px', borderRadius: "15px" }}
              ></img>
            </a>
            <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
              <img
                alt="Logo de Twitter"
                src={require("assets/img/Notaria/twitter.png")} style={{ height: '40px', borderRadius: "15px" }}
              ></img>
            </a>
            <a href="" target="_blank" style={{ textDecorationLine: "none" }} rel="noreferrer">
              <img
                alt="Logo de Facebook"
                src={require("assets/img/Notaria/facebook.png")} style={{ height: '40px', borderRadius: "15px" }}
              ></img>
            </a>
            © {new Date().getFullYear()}, Designed by{" "}
            <a
              href="https://www.sinfony.com.co/sinfony/"
              target="_blank" 
            >
              SINFONY Todos los derechos reservados.
            </a>
          </div>
          <div className="copyright float-right">
            <img
              alt="Logo Notaria"
              width="120px"
              height="100px"
              src={require("assets/img/Notaria/logo.jpg")} style={{  borderRadius: "12px" }}>
            </img>
          </div>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
