import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Procesos/Procesos.js";
import PresentationHeader from "components/Headers/PresentationHeader2.js";

function Funciones() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Funciones"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
              Funciones y deberes
            </h3>
            <Row>
              <Col md="12">
                <b>DECRETO 960 DE 1970</b>
                <br/><b>ARTICULO 3o. FUNCIONES DE LOS NOTARIOS.</b> Compete a los Notarios:
                <br/>1. Recibir, extender y autorizar las declaraciones que conforme a las Leyes requieran escritura pública y aquellas a las cuales los interesados quieran revestir de esta solemnidad.
                <br/>2. Autorizar el reconocimiento espontáneo de documentos privados.
                <br/>3. Dar testimonio de la autenticidad de firmas de funcionarios o particulares y de otros Notarios que las tengan registradas ante ellos.
                <br/>4. Dar fe de la correspondencia o identidad que exista entre un documento que tenga a la vista y su copia mecánica o literal.
                <br/>5. Acreditar la existencia de las personas naturales y expedir la correspondiente fe de vida.
                <br/>6. Recibir y guardar dentro del protocolo los documentos o actuaciones que la Ley o el Juez ordenen protocolizar o que los interesados quieran proteger de esta manera.
                <br/>7. Expedir copias o certificaciones según el caso, de los documentos que reposen en sus archivos.
                <br/>8. Dar testimonio escrito con fines jurídico - probatorio de los hechos percibidos por ellos dentro del ejercicio de sus funciones y de que no haya quedado dato formal en sus archivos.
                <br/>9. Intervenir en el otorgamiento, extensión y autorización de los testamentos solemnes que conforme a la Ley civil deban otorgarse ante ellos.
                <br/>10. Practicar apertura y publicación de los testamentos cerrados.
                <br/>11. Llevar el registro del estado civil de las personas en los casos, por los sistemas y con las formalidades prescritas en la Ley.
                <br/>14. Las demás funciones que les señalen las Leyes.
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h4 className="text-center">Decreto 960 de 1970</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{padding: "15px 15px 15px 15px"}}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0"}}>
                    <h5 className="text-center">Decreto 1069 de 2015 sector justicia y del derecho</h5>
                    <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=74174" 
                      style={{marginBottom: "20px"}}
                      role="button" className="btn btn-azul"  target="_black" aria-disabled="true">
                      <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Funciones;