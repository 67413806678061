import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function Oficina() {
  return (
    <>
      <div id="oficina" style={{backgroundColor: "#f9f9f9"}}>
        <div className="about-office">
          <Container>
            <Row className="text-center">
              <Col className="ml-auto mr-auto" md="8">
                <h1 className="title">Nuestras <b style={{color : "#bd0b0ed8"}}>Oficinas</b><br></br></h1>
                <h4 className="description">
                  <b>
                    Tenemos un ambiente adecuado para que usted realice sus trámites.
                  </b>
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4" style={{marginBottom : "24px"}}>
                <img
                  alt="Entrada Notaria"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Portada.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "24px"}}>
                <img
                  alt="Registro Civil"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina2.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Administracion"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina1.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Autenticaciones"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina3.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "18px"}}>
                <img
                  alt="Oficina Admin"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina4.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Facturacion"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina5.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Digitacion"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina6.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Caja"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina7.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Auxiliar Administracion"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina9.jpg")}
                ></img>
              </Col>
              <Col md="4" style={{marginBottom : "20px"}}>
                <img
                  alt="Asesoria Juridica"
                  className="rounded img-raised"
                  src={require("assets/img/Notaria/Oficina11.jpg")}
                ></img>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Oficina;
