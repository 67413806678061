import {CARGAR_NOTARIA} from '../types';


const initialState = {
    notaria: {}
}

export default function(state = initialState, action){
    switch(action.type){
        case CARGAR_NOTARIA:
            return{
                ...state,
                notaria: action.payload
            }
        
        case 'BORRAR_OFERTA':
        return{
            ...state,
            ofertas: state.ofertas.filter(item => item.codigo_oferta !== action.payload)
        }       
        case 'BORRAR_ALL_OFERTA':
        return{
            ...state,
            ofertas: []
        }       
        default: 
            return state
    }
}

