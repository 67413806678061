import React from "react";
import Iframe from "react-iframe";
import { useSelector} from "react-redux";
import Axios from "axios";
// reactstrap components
import {
  Alert,
  Button,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Label,
  Card,
  CardBody,
  Container,
  Row,
  Col,
} from "reactstrap";;


// core components

function Contacto() {

  const [nameFocus, setNameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [nombres, setNombres] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [mensaje, setMensaje] = React.useState("");
  const [checked, setChecked] = React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState({mostrar:false, tipo:'danger', message:''});
  const notaria = useSelector((state) => state.notaria.notaria);


  const enviarCorreo = async (e) => {
    e.preventDefault()
    if(checked && nombres !==''&& correo !=='' && mensaje !==''){
      try{
          let config = {
            method: 'POST',
            mode: 'cors',
            headers: {"Access-Control-Allow-Origin": true,Authorization: "Bearer "+ process.env.REACT_APP_TOKEN_API},
            }
            let data = {
              'remitente_name': nombres, 
              'remitente_email': correo, 
              'descripcion':mensaje,
              'destinatario': notaria.email_contactenos            
            }            
          const resultado = await Axios.post(process.env.REACT_APP_UrlApi+'MailContact',data,config)
          
          if(resultado.status === 200){
            setNombres('')
            setCorreo('')
            setMensaje('')
            setErrorEmail({mostrar:true, tipo:'success', message:'Correo Enviado, pronto le daremos una respuesta'})       
          }else{
            setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
          }
      }
      catch(eroror){
        setErrorEmail({mostrar:true, tipo:'danger', message:'Sucedió un error, intentarlo mas tarde'})
      }
    }else{
      setErrorEmail({mostrar:true, tipo:'danger', message:'Campos vacíos'})
      setTimeout(() => {
        setErrorEmail({mostrar:false, tipo:'danger', message:''})
      }, 3000)
    }
 
  }  

  return (
    <>
      <div id="contacto" style={{ backgroundColor: "White" }}>
        <Container>
          <Row className="justify-content-center">
            <Col md="10">
              <h1 className="text-center title">
                <b style={{color : "#bd0b0ed8"}}>Información </b>de contacto<br></br>
              </h1>
              <h4 className="text-center description">
                Necesita más información? Visite nuestras oficinas o comuníquese con nuestras
                líneas de atención al cliente para recibir información adicional.
              </h4>
            </Col>
          </Row>
          <Row className="justify-content-center" style={{marginTop: "20px"}}>
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h3 className="title text-center" style={{marginTop: "-25px"}}>Contáctenos por <br></br>Correo electrónico</h3>
                  <Form id="contact-form" method="post" role="form">
                    <label>Nombre</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                      </InputGroupText>
                      <Input
                        placeholder="Nombre completo"
                        type="text"
                        onFocus={() => setNameFocus(true)}
                        onBlur={() => setNameFocus(false)}
                        onChange={(e) =>setNombres(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <label>Correo electrónico</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                       <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      <Input
                        placeholder="example@email.com"
                        type="email"
                        onFocus={() => setEmailFocus(true)}
                        onBlur={() => setEmailFocus(false)}
                        onChange={(e) =>setCorreo(e.target.value)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label> Mensaje</label>
                      <Input
                        id="message"
                        name="message"
                        rows="6"
                        type="textarea"
                        onChange={(e) =>setMensaje(e.target.value)}
                      ></Input>
                    </FormGroup>
                    <FormGroup check>
                      <Label check>
                        <Input 
                          type="checkbox"
                          onChange={(e) =>setChecked(true)}
                        ></Input>
                        <span className="form-check-sign"></span>
                          No soy un robot 
                      </Label>
                    </FormGroup>
                    <br></br>
                    {errorEmail.mostrar &&
                    <Row className="justify-content-center" id="error">
                      <Col ms="6">
                        <Alert color={errorEmail.tipo}>
                          <b style={{fontSize: "16px"}}>{errorEmail.message}</b>
                        </Alert>
                      </Col>
                    </Row>
                  }
                    <div className="submit text-center">
                      <Button
                        color="azul"
                        className="btn-raised btn-round"
                        defaultValue="Enviar"
                        onClick={(e) => enviarCorreo(e)}
                      >
                        <b style={{fontSize: "16px"}}>Enviar Mensaje</b>
                      </Button>
                    </div>
                  </Form>                 
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <h3 className="title text-center" style={{marginTop: "-25px"}}>Contáctenos por <br></br>Whatsapp</h3>
                  <Row>
                    <Col md="12">
                      <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"/>
                      <a href="https://wa.me/573054407547"  className="wp">
                        <p><i className="fa fa-whatsapp my-float"></i>Notaría 2 de Floriblanca</p>
                      </a>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <h3 className="title text-center" style={{marginTop: "-25px"}}>Teléfonos</h3>
                  <Row>
                    <Col md="12">
                      <center>
                      <p><i className="now-ui-icons tech_mobile" style={{color: "#bd0b0e"}}></i> +57 (7) 6386227</p>
                        <p><i className="now-ui-icons tech_mobile" style={{color: "#bd0b0e"}}></i>Fax: +57 (7) 6848975</p>
                      </center>
                    </Col>
                  </Row>
                  <table className="tableizer-table">
                  <thead><tr className="tableizer-firstrow"><th>Extensiones</th><th>&nbsp;</th></tr></thead><tbody>
                  <tr><td>101</td><td>Conmutador</td></tr>
                  <tr><td>102</td><td>Autenticaciones</td></tr>
                  <tr><td>103</td><td>Declaraciones y Registro</td></tr>
                  <tr><td>104</td><td>Radicación</td></tr>
                  <tr><td>105</td><td>Digitación y Revisión</td></tr>
                  <tr><td>106</td><td>Lectura y Copia de Escrituras</td></tr>
                  <tr><td>107</td><td>Área administrativa y Liquidación</td></tr>
                  <tr><td>108</td><td>Asesoría Juridica</td></tr>
                  <tr><td>109</td><td>Despacho Notarial</td></tr>
                  </tbody></table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className= "justify-content-center">
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Correos institucionales:</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>control.escrituracion@notaria2floridablanca.com</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>digitacion@notaria2floridablanca.com</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>factura.electronica@notaria2floridablanca.com</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>registro.civil@notaria2floridablanca.com</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>liquidacion@notaria2floridablanca.com</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>radicacion@notaria2floridablanca.com</h4>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Correo Notificaciones Judiciales:</h4>
                  <h4 className="text-center" style={{marginTop: "-10px", fontSize: "16px"}}>segundafloridablanca@supernotariado.gov.co</h4>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Correo físico:</h4>
                  <h4 className="text-center" style={{marginTop: "-25px"}}>Cra. 26 # 30-36, Floridablanca, Santander</h4>
                  <h4 className="text-center">Código Postal: 681004</h4>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className= "justify-content-center">
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Rutas:
                  </h4>
                  <center>
                    <a href={"https://www.waze.com/livemap/directions?dir_first=poi&utm_expid=.NrMyf32jTECwHHBJyA6DAA.1&utm_referrer=http%3A%2F%2Flocalhost%3A3000%2F&to=place.ChIJdRqIF3I_aI4RXqxUy15piV8"} style={{borderBottom: "none"}}>  
                        <img src={require("assets/img/Notaria/waze.png")} alt="..." width="13%"/>
                    </a>{"    "}
                    <a  href={"https://www.google.com/maps/dir/?api=1&destination=Notaría+Segunda+de+Floridablanca,+Cra.+26+%23%2330-36,+Floridablanca,+Santander"} style={{borderBottom: "none"}}>
                        <img src={require("assets/img/Notaria/google.png")} alt="..." width="13%"/>
                    </a>
                   </center> 
                </CardBody>
              </Card>     
            </Col>
            <Col lg="6">
              <Card style={{borderRadius: "15px", padding: "30px 30px 30px 30px", backgroundColor: "#fff0"}}>
                <CardBody style={{ border: "1px solid #9A9A9A", borderRadius: "15px", backgroundColor: "#fff"}}>
                  <h4 className="title text-center" style={{marginTop: "-25px"}}>Atención al público:</h4>
                    <center>
                      <a href="/peticiones" className="btn btn-azul" aria-disabled="true" role="button">
                        <b style={{fontSize: "15px"}}> PQRS</b>
                      </a>
                    </center>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br/>
          <Row className= "justify-content-center">
            <Col lg="12">
              <h2 className="text-center title" style={{marginTop: "-20px"}}>
                  <b style={{color : "#bd0b0ed8"}}>Ubicación</b><br></br>
              </h2>
              <Card style={{borderRadius: "15px"}}>
                <CardBody>
                  <Iframe url="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d989.8705832080921!2d-73.1064522!3d7.0699395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e683f7217881a75%3A0x5f89695ecb54ac5e!2sNotar%C3%ADa%20Segunda%20de%20Floridablanca!5e0!3m2!1ses-419!2sco!4v1605286372797!5m2!1ses-419!2sco" 
                    width = "100%"
                    frameBorder = "0" 
                    height="40%" 
                    className="text-center"
                    frameborder="0"
                    allowfullscreen="" 
                    aria-hidden="false" 
                    tabindex="0">
                  </Iframe>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Card>
            <b style={{marginTop:'100px', marginLeft:'2em'}}>Entidades, agremiaciones y asociaciones</b>
            <CardBody>
              <Row>
                <Col md="2" sm="1">
                  <a href="http://www.minjusticia.gov.co/" target="_blank" rel="noreferrer" style={{borderBottom: "none"}}>
                    <img
                      alt="Logo  Ministerio de Justicia"
                      src={require("assets/img/Notaria/justicia.png")}
                    ></img>
                  </a>
                </Col>
                <Col md="2" sm="1">
                  <a href="https://www.supernotariado.gov.co/" rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                    <img
                      alt="Logo SRN y REG"
                      src={require("assets/img/Notaria/LOGO_SNR.png")}
                   ></img>
                  </a>
                </Col>
                <Col md="2" sm="1">
                <a href="https://www.registraduria.gov.co/" rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                  <img
                      alt="Logo Registraduria Nacional del Estado Civil"
                      width="100px"
                      src={require("assets/img/Notaria/registraduria.png")}
                  ></img>
                  </a>
                </Col>
                <Col md="2" sm="1">
                  <a href="https://www.suin-juriscol.gov.co/" rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                    <img
                      alt="LOgo SUIN Juriscol"
                      width="100px"
                      src={require("assets/img/Notaria/suin.png")}
                  ></img>
                  </a>
                </Col>
                <Col md="2" sm="2">
                  <a href="https://www.vur.gov.co/" rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                    <img
                      alt="Logo VUR"
                      width="100px"
                      src={require("assets/img/Notaria/vur.png")}
                    ></img>
                  </a>
                </Col>
                <Col md="1" sm="1">
                  <a href="http://www.procuraduria.gov.co/portal/" rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                    <img
                      alt="Logo Procuraduria General de la Nacion"
                      src={require("assets/img/Notaria/procuraduria.jpg")}
                    ></img>
                  </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card>
            <b style={{ marginLeft:'2em'}}>Directorio de agremiaciones, asociaciones y otros grupos de interés</b>
            <CardBody>
              <Row>
                <Col md="2" sm="5">
                  <a href="https://www.gobiernoenlinea.gov.co/" rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                    <img
                      alt="Logo Gobierno"
                      src={require("assets/img/Notaria/gobierno.png")}
                    ></img>
                  </a>
                </Col>
                <Col md="2" sm="5">
                  <a href="https://www.ucnc.com.co/sitio/"  rel="noreferrer" target="_blank" style={{borderBottom: "none"}}>
                    <img
                      alt="Union Colombiana de Notariado"
                      width="120px"
                      src={require("assets/img/Notaria/notario.jpg")}
                    ></img>
                    </a>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <br></br>
        </Container>
      </div>
    </>
  );
}

export default Contacto;
