import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button,
          Row, 
    Col,
} from "reactstrap";

// core components

function Organigrama(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered" size="lg">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={props.setModal}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
                <h3 className="title text-center" style={{color:"#2c2c2c"}} id="exampleModalLiveLabel">
                  Organigrama
                </h3>
                <img
                      alt="..."
                      width="100%"
                      src={require("assets/img/Notaria/organigrama2.jpg")}
                  ></img>

                  <Row className="justify-content-center">
                    
                    <Col md="2">
                      <center>
                        <a href={require("../../assets/pdf/Manual de Procedimientos y Funciones Not2Flo (1).pdf")} style={{textDecoration: "none !important"}} target="_black">
                          <img
                          alt="..."
                          width="80px"
                          src={require("../../assets/img/Notaria/PDF.png")}
                          ></img>
                          <h6>Manual de Procedimientos y Funciones</h6>
                        </a>
                      </center>
                    </Col>

                    <Col md="2">
                      <center>
                        <a href={require("../../assets/pdf/organigrama.pdf")} style={{textDecoration: "none !important"}} target="_black">
                          <img
                          alt="..."
                          width="80px"
                          src={require("../../assets/img/Notaria/PDF.png")}
                          ></img>
                          <h6>Organigrama</h6>
                        </a>
                      </center>
                    </Col>
                  </Row>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="default"
                    type="button"
                    onClick={props.setModal}
                    className="text-center"
                >
                    <b style={{fontSize: "17px"}}>Cerrar</b>
                </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Organigrama;
