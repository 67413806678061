import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody,
    CardImg,
    CardText
} from "reactstrap";
// core components

// sections for this page
import PresentationHeader from "components/Headers/PresentationHeader2.js";
// import Politica from "./Notario/Notario.js";


function Notario() {
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Biografía del Notario"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row>
              <Col md="12">
                <Card style={{marginTop:"2%"}}>
                  <CardBody>
                    <Row>
                      <Col md ="4">
                        <Card style={{borderRadius: "15px"}}>
                          <CardImg
                            alt="Imagen Notario"
                            src={require("assets/img/Notaria/ofi3.jpg")}
                            top
                          ></CardImg>
                          <CardBody>
                            <CardText className="text-center" >
                              <b>Álvaro Julián Tavera Salazar</b>
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col md="8">
                        <Row>
                          <Col md="5">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h5><b style={{color: "#bd0b0e"}}>País: </b> Colombia</h5>
                                <h5><b style={{color: "#bd0b0e"}}>Departamento: </b> Santander</h5>
                                <h5><b style={{color: "#bd0b0e"}}>Ciudad:  </b> Bucaramanga</h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h5 className="text-justify">
                                  Abogado y Especialista en Derecho de Público de la Universidad Autónoma de Bucaramanga (UNAB). Cuenta con más de 10 años de experiencia profesional, dentro de los cuales ha ejercido cargos como Director Administrativo Banco de Tierras y Castatro, Director Administrativo de Predical y Asesor Júrido de diferentes entidades. Tambíen cuenta con más de 15 años de expericia como empresario, lo cual le ha permito desarrollar habilidades gerenciales.
                                </h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>    
                        <Row>
                          <Col md="12">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h5 className="text-justify">
                                <b>FORMACION ACADEMICA</b>
                                <br/><b>UNIVERSITARIOS</b>
                                <br/>•Abogado                                               
                                <br/>•Especialista en Derecho Público
                                <br/>•Especialista en Notariado y Registro
                                <br/><b>EXPERIENCIA LABORAL Y PROFESIONAL</b>
                                <br/>•Abogado Especialista en Derecho Público, con más de 10 años de experiencia laboral profesional y 15 años de experiencia como empresario.
                                </h5>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12">
                            <Card style={{borderRadius: "15px"}}>
                              <CardBody>
                                <h5><b style={{color: "#bd0b0e"}}>Correo Institucional: </b> segundafloridablanca@supernotariado.gov.co</h5>
                                <h5><b style={{color: "#bd0b0e"}}>Teléfono: </b> +57 (7) 6386227</h5>         
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Notario;