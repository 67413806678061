import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Provider } from 'react-redux';
import store from './store';
import * as serviceWorker from "./serviceWorker";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/react-demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";

// pages
import { DropdownWhiteNavbar } from "components/Navbars";
import PresentationHeader from "components/Headers/PresentationHeader";
import Whatsapp from "views/presentation-sections/Whatsapp.js"
import  { FooterBlack } from "components/Footers"
import Tramites from "views/Tramites.js";
import Presentation from "views/Presentation.js";
import Cotizador from "views/Cotizador2/CotizadorW.js"
import Solicitudes from "views/Solicitudes.js";
import Transparencias from "views/Transparencia/Transpa.js";
import Politicas from "views/Politicas.js";
import Servicios from "views/presentation-sections/Servicios.js"
import Notario from "views/Notario.js";
import Informacion from "views/Informacion.js";
import Proceso from "views/Procesos.js";
import Normativa from "views/Normativa.js";
import Tarifas from "views/Tarifas.js";
import Formatos from "views/Formato.js";
import Ninos from "views/Ninos.js";
import Mujeres from "views/Mujeres.js";
import Otrosin from "views/Otrosin.js";
import GestionDocumental from "views/GestionDocumental.js";
import GestionInformacion from "views/GestionInformacion";
import ClasificadaReservada from "views/ClasificadaReservada.js";
import RetencionDocumental from "views/RetencionDocumental.js";
import TerminosCondiciones from "views/TerminosCondiciones.js";
import CertificadoAccesibilidad from "views/CertificadoAccesibilidad.js";
import PoliticaDerechosAutor from "views/PoliticaDerechosAutor.js";
import PoliticaDerechosWeb from "views/PoliticaDerechosWeb";
import PoliticaTratamientosDatos from "views/PoliticaTratamientosDatos.js";
import AfectarPublico from "views/AfectarPublico.js";
import Participa from "views/Participa/Participa.js";
import Diagnostico from "views/Participa/diagnostico-identificacion-problemas.js";
import Planeacion from "views/Participa/planeacion-presupuesto-participativo.js";
import Rendicion from "views/Participa/rendicion-cuenta.js";
import Consulta from "views/Participa/consulta-ciudadana";
import Control from "views/Participa/control-social";
import Colaboracion from "views/Participa/colaboracion-innovacion-abierta";
import MapaSitio from "views/MapaSitio.js"
import Mision from "views/MisionVision.js";
import Funciones from "views/Funciones.js";
import Organigrama from "views/Organigrama.js";
import MapaProcesos from "views/MapaProcesos.js"
import Entes from "views/Entes.js"
import Planeacion2 from "views/Planeacion.js"
import "config"

// others

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <div className='dark-mode'>
        <DropdownWhiteNavbar />
        
        <Routes>
          <Route
            path="/formato"
            element={<Formatos />}
          />
          <Route
            path="/tarifa"
            element={<Tarifas />}
          />
          <Route
            path="/notario"
            element={<Notario />}
          />
          <Route
            path="/normativa"
            element={<Normativa />}
          />
          <Route
            path="/procesos"
            element={<Proceso />}
          />
          <Route
            path="/servicios"
            element={<Servicios/>}
          />
          <Route
            path="/informacion"
            element={<Informacion />}
          />
          <Route
            path="/PoliticaTratamientosDatos"
            element={<PoliticaTratamientosDatos />}
          />
          <Route
            path="/PoliticaDerechosAutor"
            element={<PoliticaDerechosAutor />}
          />
          <Route
            path="/PoliticaDerechosWeb"
            element={<PoliticaDerechosWeb />}
          />
          <Route
            path="/CertificadoAccesibilidad"
            element={<CertificadoAccesibilidad />}
          />
          <Route
            path="/TerminosCondiciones"
            element={<TerminosCondiciones />}
          />
          <Route
            path="/MapaSitio"
            element={<MapaSitio />}
          />
          <Route
            path="/participa" 
            element={<Participa/>}
          />
          <Route
            path="/participacion-para-el-diagnostico-de-necesidades-e-identificacion-de-problemas" 
            element={<Diagnostico/>}
          />
          <Route
            path="/planeacion-y-presupuesto-participativo" 
            element={<Planeacion/>}
          />
          <Route
            path="/consulta-ciudadana" 
            element={<Consulta/>}
          />
          <Route
            path="/rendicion-de-cuentas" 
            element={<Rendicion/>}
          />
          <Route
            path="/control-social" 
            element={<Control/>}
          />
          <Route
            path="/colaboracion-e-innovacion-abierta" 
            element={<Colaboracion/>}
          />
          <Route
            path="/transparencia"
            element={<Transparencias />}
          />
          <Route
            path="/peticiones"
            element={<Solicitudes />}
          />
          <Route
            path="/cotizar"
            element={<Cotizador />}
          />
          <Route
            path="/tramite"
            element={<Tramites />}
          />
          <Route
            path="/ClasificadaReservada"
            element={<ClasificadaReservada />}
          />
          <Route
            path="/GestionDocumental"
            element={<GestionDocumental />}
          />
          <Route
            path="/GestionInformacion"
            element={<GestionInformacion />}
          />
          <Route
            path="/RetencionDocumental"
            element={<RetencionDocumental />}
          />
          <Route
            path="/AfectarPublico"
            element={<AfectarPublico />}
          />
          <Route
            path="/Mujeres"
            element={<Mujeres />}
          />
          <Route
            path="/Otrosin"
            element={<Otrosin />}
          />
          <Route
            path="/Ninos"
            element={<Ninos />}
          />
          <Route
            path="/politicas"
            element={<Politicas />}
          />
          <Route
            path="/Mision"
            element={<Mision />}
          />
          <Route
            path="/Organigrama"
            element={<Organigrama />}
          />
          <Route
            path="/Funciones"
            element={<Funciones />}
          />
          <Route
            path="/MapaProcesos"
            element={<MapaProcesos />}
          />
          <Route
            path="/Entes"
            element={<Entes />}
          />
          <Route
            path="/Planeacion"
            element={<Planeacion2 />}
          />
          <Route
            path="/"
            element={<Presentation />}
          />
          {/*<Route path="*" element={<Navigate to="/" />} /> */}
        </Routes>
        <Whatsapp />
        <FooterBlack />
      </div>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister()
