import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col
} from "reactstrap";
// core components

// sections for this page
import PresentationHeader from "components/Headers/PresentationHeader2.js";

function Entes() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Informes de gestión, evaluación y auditoría"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Informes de gestión, evaluación y auditoría
            </h3>
            <Row className="justify-content-center">
              <Col md="10">
                <h4 className="text-justify">
                  "Los informes que presentan los Señores Notarios a la Superintendencia de Notariado y Registro son:<br></br>
                  <br></br>
                  Informe Estadistico Notarial, cuya información financiera y contable es reservada. Artículo 61
                  del Código de Comercio. El informe estadistico que remiten mensualmente las Notarías a la SNR a
                  través de los correos habilitados para ello, según la Instrucción Administrativa 4 de 2019.<br></br>
                  <br></br>
                  Los informes que rinden los Señores Notarios a la UIAF son:<br></br>
                  <br></br>
                  Reporte de Operación Notarial (RON).<br></br>
                  Reporte de Operaciones intentadas y operaciones sospechosas ROS.<br></br>
                  Reporte de ausencia de operaciones sospechosos<br></br>
                  Reporte de transacciones en efectivo.<br></br>
                  Reporte de ausencia de transacciones en efectivo.<br></br>
                  Reporte de adjudicaciones en remate.<br></br>
                  <br></br>
                  Los informes que allegan los Señores Notarios a la DIAN son:<br></br>
                  <br></br>
                  Información exogena."
                  </h4>
                  <a href="https://www.supernotariado.gov.co/transparencia/planeacion-presupuesto-e-informes/auditorias-de-gestion-calidad-e-integrales/" target="_black">
                    <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subSubItem">4.7.5.b. Enlace al organismo de control donde se encuentren los informes que éste ha elaborado
                      en relación con el sujeto obligado. </b></h6>
                  </a>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Entes;