import React, { useEffect } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

// core components
import Funciones from "views/Molda/Funciones.js";
import Organigrama from "views/Molda/Organigrama.js";
// import Tramites from "views/Molda/Tramites.js";
import Mision from "../../views/Molda/Mision.js";

//import './Transpa.css'


/*
..#######..########..########.##....##
.##.....##.##.....##.##.......###...##
.##.....##.##.....##.##.......####..##
.##.....##.########..######...##.##.##
.##.....##.##........##.......##..####
.##.....##.##........##.......##...###
..#######..##........########.##....##
*/
function open1() {
  document.getElementById("menu1").classList.add('animate__fadeIn');
  document.getElementById("menu1").style.display = "block";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";

}
function open2() {
  document.getElementById("menu2").classList.add('animate__fadeIn');
  document.getElementById("menu2").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open3() {
  document.getElementById("menu3").classList.add('animate__fadeIn');
  document.getElementById("menu3").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open4() {
  document.getElementById("menu4").classList.add('animate__fadeIn');
  document.getElementById("menu4").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open5() {
  document.getElementById("menu5").classList.add('animate__fadeIn');
  document.getElementById("menu5").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open6() {
  document.getElementById("menu6").classList.add('animate__fadeIn');
  document.getElementById("menu6").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open7() {
  document.getElementById("menu7").classList.add('animate__fadeIn');
  document.getElementById("menu7").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open8() {
  document.getElementById("menu8").classList.add('animate__fadeIn');
  document.getElementById("menu8").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open9() {
  document.getElementById("menu9").classList.add('animate__fadeIn');
  document.getElementById("menu9").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu2").style.display = "none";
  document.getElementById("menu10").style.display = "none";
}
function open10() {
  document.getElementById("menu10").classList.add('animate__fadeIn');
  document.getElementById("menu10").style.display = "block";
  document.getElementById("menu1").style.display = "none";
  document.getElementById("menu3").style.display = "none";
  document.getElementById("menu4").style.display = "none";
  document.getElementById("menu5").style.display = "none";
  document.getElementById("menu6").style.display = "none";
  document.getElementById("menu7").style.display = "none";
  document.getElementById("menu8").style.display = "none";
  document.getElementById("menu9").style.display = "none";
  document.getElementById("menu2").style.display = "none";
}

function Transpa() {

  let navigate = useNavigate();



  const [modal1, setModal1] = React.useState(false);
  const [modal2, setModal2] = React.useState(false);
  const [modal3, setModal3] = React.useState(false);
  const [modal4, setModal4] = React.useState(false);
  const [modal5, setModal5] = React.useState(false);
  const [modal6, setModal6] = React.useState(false);
  const [modal7, setModal7] = React.useState(false);
  const [modal8, setModal8] = React.useState(false);
  const [modal9, setModal9] = React.useState(false);
  const [modal10, setModal10] = React.useState(false);
  const [modal11, setModal11] = React.useState(false);
  const [modal12, setModal12] = React.useState(false);
  const [modal13, setModal13] = React.useState(false);
  const [modal14, setModal14] = React.useState(false);
  const [modal15, setModal15] = React.useState(false);
  const [modal16, setModal16] = React.useState(false);
  const [modal17, setModal17] = React.useState(false);
  const [modal18, setModal18] = React.useState(false);
  const [modal19, setModal19] = React.useState(false);
  let { modalNombre } = useParams();

  //Funciones para reemplazar la url cuando se abra un modal en especifico
  const handleModal1 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/datosAbiertos' });
      setModal1(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal1(false);
    }
  }

  const handleModal2 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/preguntasFrecuentes' });
      setModal2(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal2(false);
    }
  }

  const handleModal3 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/glosario' });
      setModal3(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal3(false);
    }
  }

  const handleModal19 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/calendarioActividades' });
      setModal19(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal19(false);
    }
  }

  const handleModal15 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/misionYvision' });
      setModal15(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal15(false);
    }
  }

  const handleModal4 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/funcionesYdeberes' });
      setModal4(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal4(false);
    }
  }

  const handleModal5 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/organigrama' });
      setModal5(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal5(false);
    }
  }

  const handleModal17 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/directorioEntidades' });
      setModal17(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal17(false);
    }
  }

  const handleModal6 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/agremiaciones' });
      setModal6(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal6(false);
    }
  }

  const handleModal7 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/informesGestion' });
      setModal7(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal7(false);
    }
  }

  const handleModal8 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/entesControl' });
      setModal8(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal8(false);
    }
  }

  const handleModal16 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/poblacionVulnerable' });
      setModal16(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal16(false);
    }
  }

  const handleModal9Cerrar = (value) => {
    if (!value) {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal9(false);
    }
  }

  const handleModal9Tramites = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/tramites' });
      setModal9(true);
    }
  }

  const handleModal9Normatividad = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/normatividad' });
      setModal9(true);
    }
  }

  const handleModal9ProtocolosAtencion = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/protocolosAtencion' });
      setModal9(true);
    }
  }

  const handleModal9Tarifas = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/tarifas' });
      setModal9(true);
    }
  }

  const handleModal9FormatosYFormularios = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/formatosYformularios' });
      setModal9(true);
    }
  }

  const handleModal11 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/informePeticiones' });
      setModal11(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal11(false);
    }
  }

  const handleModal12 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/sitioEnConstruccion' });
      setModal12(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal12(false);
    }
  }

  const handleModal13 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/formatoGruposEtnicos' });
      setModal13(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal13(false);
    }
  }

  const handleModal14 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/accesibilidadMediosElectronicos' });
      setModal14(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal14(false);
    }
  }

  const handleModal18 = (value) => {
    if (value) {
      navigate({ pathname: '/transparencia/protocoloSeguridad' });
      setModal18(true);
    } else {
      navigate({ pathname: '/transparencia/informacionPublica' });
      setModal18(false);
    }
  }

  useEffect(() => {

    if (modalNombre) {
      switch (modalNombre) {
        case 'datosAbiertos':
          setModal1(true);
          open2();
          break;
        case 'preguntasFrecuentes':
          setModal2(true);
          open2();
          break;
        case 'glosario':
          setModal3(true);
          open2();
          break;
        case 'calendarioActividades':
          setModal19(true);
          open2();
          break;
        case 'misionYvision':
          setModal15(true);
          open1();
          break;
        case 'funcionesYdeberes':
          setModal4(true);
          open1();
          break;
        case 'organigrama':
          setModal5(true);
          open1();
          break;
        case 'directorioEntidades':
          setModal17(true);
          open1();
          break;
        case 'agremiaciones':
          setModal6(true);
          open1();
          break;
        case 'informesGestion':
          setModal7(true);
          open5();
          break;
        case 'entesControl':
          setModal8(true);
          open1();
          break;
        case 'poblacionVulnerable':
          setModal16(true);
          open5();
          break;
        case 'tramites':
          setModal9(true);
          open6();
          break;
        case 'normatividad':
          setModal9(true);
          open4();
          break;
        case 'protocolosAtencion':
          setModal9(true);
          open4();
          break;
        case 'tarifas':
          setModal9(true);
          open4();
          break;
        case 'formatosYformularios':
          setModal9(true);
          open4();
          break;
        case 'informePeticiones':
          setModal11(true);
          open7();
          break;
        case 'estadosSolicitudesInformacionPublica':
          setModal12(true);
          open8();
          break;
        case 'formatoGruposEtnicos':
          setModal13(true);
          open9();
          break;
        case 'accesibilidadMediosElectronicos':
          setModal14(true);
          open9();
          break;
        case 'protocoloSeguridad':
          setModal18(true);
          open10();
          break;
        case '0':
          navigate({ pathname: '/transparencia/informacionPublica' })
          break;
        default:
          break;
      }
    }


    // <--------------------- ------------------------>
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let modal

    if (urlParams.has('modal')) {
      modal = urlParams.get('modal')
      switch (modal) {
        case '2.1': setModal1(true); break;
        case '2.4': setModal2(true); break;
        case '2.5': setModal3(true); break;
        case '3.2': setModal4(true); break;
        case '3.4': setModal5(true); break;
        case '3.7': setModal6(true); break;
        case '7.1': setModal7(true); break;
        case '7.4': setModal8(true); break;
        case '9.1': setModal9(true); break;
        case '9.1.1': setModal9(true); break;
        case '9.1.2': setModal9(true); break;
        case '9.1.3': setModal9(true); break;
        case '9.1.4': setModal9(true); break;
        case '10.10': setModal11(true); break;
        case '11.1': setModal12(true); break;
        case '12.1': setModal13(true); break;
        case '12.2': setModal14(true); break;
        case '3.1': setModal15(true); break;
        case '7.5': setModal16(true); break;
        case '3.6': setModal17(true); break;
        case '13.5': setModal18(true); break;
        case '13.6': setModal19(true); break;
        case '6.1.8': setModal10(true); break;
        default:
      }
    }
  }, [])
  return (
    <>
      <div id="servicio" style={{ backgroundColor: "#fdfcff", marginTop: "12%" }}>
        <Container className="fluid">
          <Row className="justify-content-center">
            <Card>
              <CardBody>
                <Row>
                  {/*
                    ....##......##.....##.########..######.....###....##....##.####..######..##.....##..#######.
                    ..####......###...###.##.......##....##...##.##...###...##..##..##....##.###...###.##.....##
                    ....##......####.####.##.......##........##...##..####..##..##..##.......####.####.##.....##
                    ....##......##.###.##.######...##.......##.....##.##.##.##..##...######..##.###.##.##.....##
                    ....##......##.....##.##.......##.......#########.##..####..##........##.##.....##.##.....##
                    ....##......##.....##.##.......##....##.##.....##.##...###..##..##....##.##.....##.##.....##
                    ..######....##.....##.########..######..##.....##.##....##.####..######..##.....##..#######.
                    */}
                  {/* BOTON */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open1}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>1. INFORMACIÓN DE LA ENTIDAD</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu1" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            {/* 1.1 */}
                            <h6 className="info-title"><b>1.1 Misión, Visión, Funciones y deberes.</b></h6>
                            <a href="/Mision">
                            <h6 className="info-title texto" style={{ cursor: "pointer" }}  ><b className="subItem">1.1.a. Misión y Visión</b></h6>
                            </a>
                            <a href="/Funciones">
                            <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">1.1.b. Funciones y deberes</b></h6>
                            </a>
                            {/* 1.2 */}
                            <h6 className="info-title"><b>1.2. Estructura orgánica - organigrama</b></h6>
                            <a href="/Organigrama">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">1.2.a. Organigrama</b></h6>
                            </a>
                            {/* 1.3 */}
                            <h6 className="info-title"><b>1.3. Mapas y cartas descriptivas de los procesos</b></h6>
                            <a href="/MapaProcesos">
                              <h6 className="info-title texto"><b className="subItem">1.3.a. Mapas y cartas descriptivas de los procesos</b></h6>
                            </a>
                            {/* 1.4 */}
                            <h6 className="info-title" ><b>1.4. Directorio Institucional incluyendo sedes, oficinas, sucursales, o regionales, y dependencias.</b></h6>
                            <a href="/#contacto">
                              <h6 className="info-title texto"><b className="subItem">1.4.a. Información de contacto</b></h6>
                            </a>
                            <a href="/#contacto">
                              <h6 className="info-title texto"><b className="subItem">1.4.b. Ubicación física (nombre de la sede si aplica).</b></h6>
                            </a>
                            <a href="/#contacto">
                              <h6 className="info-title texto"><b className="subItem">1.4.c. Dirección  (incluyendo el departamento si aplica) y municipio o distrito (en caso que aplique,
                                se deberá indicar el nombre del corregimiento).</b></h6>
                            </a>
                            <a href="/">
                              <h6 className="info-title texto"><b className="subItem">1.4.d. Horarios y días de atención al público.</b></h6>
                            </a>
                            <a href="/#contacto">
                              <h6 className="info-title texto"><b className="subItem">1.4.e. Datos de contacto específicos de las áreas de contacto o dependencias (en caso de que aplique).</b></h6>
                            </a>
                            {/* 1.5 */}
                            <h6 className="info-title"><b>1.5 Directorio de servidores públicos, empleados o contratistas.</b></h6>
                            <h6 className="info-title texto" style={{ cursor: "pointer" }} onClick={() => handleModal17(true)}><b className="subItem">1.5.1. Listado de entidades que integran el sector.</b></h6>
                            {/* 1.6 */}
                            <h6 className="info-title"><b>1.6. Directorio de entidades.</b></h6>
                            <h6 className="info-title texto" style={{ cursor: "pointer" }} onClick={() => handleModal17(true)}><b className="subItem">1.6.1 Directorio de entidades.</b></h6>
                            {/* 1.7 */}
                            <h6 className="info-title"><b>1.7. Directorio de agremiaciones, asociaciones y otros grupos de interés.</b></h6>
                            <h6 className="info-title texto" style={{ cursor: "pointer" }} onClick={() => handleModal6(true)}><b className="subItem">1.7.1. Directorio de agremiaciones, asociaciones y otros grupos de interés.</b></h6>
                          </Col>
                          <Col md="6">
                            <h6 className="info-title"><b>1.8 Servicio al público, normas, formularios y protocolos de atención.</b></h6>
                            <a href="/#contacto">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">1.8.1. Servicio al público, normas, formularios y protocolos de atención.</b></h6>
                            </a>
                            <a href="/normativa">
                              <h6 className="info-title texto"><b className="subItem">1.8.2. Normas.</b></h6>
                            </a>
                            <a href="/peticiones">
                              <h6 className="info-title texto"><b className="subItem">1.8.3. Formularios.</b></h6>
                            </a>
                            <a href="/#contacto">
                              <h6 className="info-title texto"><b className="subItem">1.8.4. Protocolos de Atención.</b></h6>
                            </a>
                            {/* 1.9 */}
                            <h6 className="info-title" ><b>1.9 Procedimientos que se siguen para tomar decisiones en las diferentes áreas.</b></h6>
                            <a href="/procesos">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">1.9.1 Procedimientos que se siguen para tomar decisiones en las diferentes áreas</b></h6>
                            </a>
                            <h6 className="info-title"><b>1.10. Mecanismo de presentación directa de solicitudes, quejas y reclamos a disposición
                              del público en relación con acciones u omisiones del sujeto obligado.</b></h6>
                            <a href="/peticiones">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">1.10.1. Mecanismo de presentación directa de solicitudes, quejas y reclamos.</b></h6>
                            </a>
                            <a href="/AfectarPublico">
                              <h6 className="info-title"><b>1.12 Información sobre decisiones que pueden afectar al público.</b></h6>
                            </a>
                            <a href="/Entes">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b>1.13 Entes y autoridades que lo vigilan.</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                   .....#######......####.##....##.########..#######..########..##.....##....###.....######..####..#######..##....##
                   ....##.....##......##..###...##.##.......##.....##.##.....##.###...###...##.##...##....##..##..##.....##.###...##
                   ...........##......##..####..##.##.......##.....##.##.....##.####.####..##...##..##........##..##.....##.####..##
                   .....#######.......##..##.##.##.######...##.....##.########..##.###.##.##.....##.##........##..##.....##.##.##.##
                   ....##.............##..##..####.##.......##.....##.##...##...##.....##.#########.##........##..##.....##.##..####
                   ....##........###..##..##...###.##.......##.....##.##....##..##.....##.##.....##.##....##..##..##.....##.##...###
                   ....#########.###.####.##....##.##........#######..##.....##.##.....##.##.....##..######..####..#######..##....##
                   */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open2}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>2. NORMATIVA</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu2" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title "><b>2.1. Normativa de la entidad o autoridad</b></h6>
                            <a href="/normativa">
                              <h6 className="info-title texto"><b className="subItem">2.1.1. Leyes.</b></h6>
                            </a>
                            <a href="/normativa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">2.1.2. Decreto Único Reglamentario.</b></h6>
                            </a>
                            <a href="/normativa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">2.1.3. Normativa aplicable.</b></h6>
                            </a>
                            <a href="http://svrpubindc.imprenta.gov.co/diario/" target="_black">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">2.1.4. Vínculo al Diario o Gaceta Oficial.</b></h6>
                            </a>
                            <a href="/normativa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">2.1.5. Políticas, lineamientos y manuales.</b></h6>
                            </a>
                          </Col>
                          <Col md="6">
                            <h6 className="info-title "><b>2.2. Búsqueda de normas.</b></h6>
                            <a href="https://www.suin-juriscol.gov.co/" target="_black">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">2.2.1. Sistema Único de Información Normativa – SUIN.</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    ..#######......########..######..########.########..##.....##..######..########.##.....##.########.....###...
                    .##.....##.....##.......##....##....##....##.....##.##.....##.##....##....##....##.....##.##.....##...##.##..
                    ........##.....##.......##..........##....##.....##.##.....##.##..........##....##.....##.##.....##..##...##.
                    ..#######......######....######.....##....########..##.....##.##..........##....##.....##.########..##.....##
                    ........##.....##.............##....##....##...##...##.....##.##..........##....##.....##.##...##...#########
                    .##.....##.###.##.......##....##....##....##....##..##.....##.##....##....##....##.....##.##....##..##.....##
                    ..#######..###.########..######.....##....##.....##..#######...######.....##.....#######..##.....##.##.....##
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open3}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>4. PLANEACIÓN</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu3" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title" ><b>4.7 Informes de gestión, evaluación y auditoría. </b></h6>
                            <a href="/Planeacion" target="_black">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}  ><b className="subItem">4.7.4. Informes a organismos de inspección, vigilancia y control. </b></h6>
                            </a>
                              <h6 className="info-title texto" ><b className="subItem">4.7.5. Planes de mejoramiento: </b></h6>
                            <a href="/Planeacion" target="_black">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subSubItem">4.7.5.b. Enlace al organismo de control donde se encuentren los informes que éste ha elaborado
                                en relación con el sujeto obligado. </b></h6>
                            </a>
                          </Col>
                          <Col md="6">
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    .##............##....##..#######..########..##.....##....###....########.####.##.....##.####.########.....###....########.
                    .##....##......###...##.##.....##.##.....##.###...###...##.##......##.....##..##.....##..##..##.....##...##.##...##.....##
                    .##....##......####..##.##.....##.##.....##.####.####..##...##.....##.....##..##.....##..##..##.....##..##...##..##.....##
                    .##....##......##.##.##.##.....##.########..##.###.##.##.....##....##.....##..##.....##..##..##.....##.##.....##.##.....##
                    .#########.....##..####.##.....##.##...##...##.....##.#########....##.....##...##...##...##..##.....##.#########.##.....##
                    .......##..###.##...###.##.....##.##....##..##.....##.##.....##....##.....##....##.##....##..##.....##.##.....##.##.....##
                    .......##..###.##....##..#######..##.....##.##.....##.##.....##....##....####....###....####.########..##.....##.########.
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open4}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>5. TRÁMITES</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu4" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title"><b>5.1 Informes de gestión, evaluación y auditoría.</b></h6>
                            <a href="/tramite">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subSubItem">5.1.1 .Normatividad que sustenta el trámite. </b></h6>
                            </a>
                            <a href="/tramite">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subSubItem">5.1.2. Procesos.</b></h6>
                            </a>
                            <a href="/tarifa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subSubItem">5.1.3. Costos asociados. </b></h6>
                            </a>
                            <a href="/tramite">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subSubItem">5.1.4. Formatos y/o formularios asociados.</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    .########......######...#######..##....##.########.########...#######..##......
                    .##....##.....##....##.##.....##.###...##....##....##.....##.##.....##.##......
                    .....##.......##.......##.....##.####..##....##....##.....##.##.....##.##......
                    ....##........##.......##.....##.##.##.##....##....########..##.....##.##......
                    ...##.........##.......##.....##.##..####....##....##...##...##.....##.##......
                    ...##.....###.##....##.##.....##.##...###....##....##....##..##.....##.##......
                    ...##.....###..######...#######..##....##....##....##.....##..#######..########
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open5}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>6. PARTICIPA</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu5" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <a href="/participa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b>6.1 Descripción General.</b></h6>
                            </a>
                            <a href="/participa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b>6.2 Estructura y Secciones del menú "PARTICIPA".</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    ..#######......########.########.....###....##.....##.####.########.########..######.
                    .##.....##........##....##.....##...##.##...###...###..##.....##....##.......##....##
                    .##.....##........##....##.....##..##...##..####.####..##.....##....##.......##......
                    ..########........##....########..##.....##.##.###.##..##.....##....######....######.
                    ........##........##....##...##...#########.##.....##..##.....##....##.............##
                    .##.....##.###....##....##....##..##.....##.##.....##..##.....##....##.......##....##
                    ..#######..###....##....##.....##.##.....##.##.....##.####....##....########..######.
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open6}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>7. DATOS ABIERTOS</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu6" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title"><b>7.1 Instrumentos de gestión de la información.</b></h6>
                            <a href="/GestionInformacion">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">7.1.1 Registros de activos de información</b></h6>
                            </a>
                            <a href="/ClasificadaReservada">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">7.1.2 Índice de información clasificada y reservada.</b></h6>
                            </a>
                            <a href="/GestionInformacion">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">7.1.3 Esquema de publicación de la información</b></h6>
                            </a>
                            <a href="/GestionDocumental">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">7.1.4 Programa de gestión documental.</b></h6>
                            </a>
                            <a href="/RetencionDocumental">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">7.1.5. Tablas de retención documental.</b></h6>
                            </a>
                          </Col>
                          <Col md="6">
                            <h6 className="info-title"><b>7.2 Sección de Datos Abiertos</b></h6>
                            <a href="http://datos.gov.co/" target="_black">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }} ><b className="subItem">7.2.1 Datos Abiertos</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    ....##.....#####.......####.##....##..######..########.########..##.....##.##.....##.########.##....##.########..#######...######.
                    ..####....##...##.......##..###...##.##....##....##....##.....##.##.....##.###...###.##.......###...##....##....##.....##.##....##
                    ....##...##.....##......##..####..##.##..........##....##.....##.##.....##.####.####.##.......####..##....##....##.....##.##......
                    ....##...##.....##......##..##.##.##..######.....##....########..##.....##.##.###.##.######...##.##.##....##....##.....##..######.
                    ....##...##.....##......##..##..####.......##....##....##...##...##.....##.##.....##.##.......##..####....##....##.....##.......##
                    ....##....##...##..###..##..##...###.##....##....##....##....##..##.....##.##.....##.##.......##...###....##....##.....##.##....##
                    ..######...#####...###.####.##....##..######.....##....##.....##..#######..##.....##.########.##....##....##.....#######...######.
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open7}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>8. INFORMACIÓN ESPECÍFICA PARA GRUPOS DE INTERÉS.</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu7" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title"><b>8.1. Información para  Grupos Específicos.</b></h6>
                            <a href="/Ninos">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">8.1.1. Información para niños, niñas y adolescentes.</b></h6>
                            </a>
                            <a href="/Mujeres">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">8.1.2. Información para mujeres.</b></h6>
                            </a>
                            <a href="/Otrosin">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">8.1.3. Otros de grupos de interés.</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    ....##......##.......########.########.....###....##....##..######..########.....###....########..########.##....##..######..####....###...
                    ..####....####..........##....##.....##...##.##...###...##.##....##.##.....##...##.##...##.....##.##.......###...##.##....##..##....##.##..
                    ....##......##..........##....##.....##..##...##..####..##.##.......##.....##..##...##..##.....##.##.......####..##.##........##...##...##.
                    ....##......##..........##....########..##.....##.##.##.##..######..########..##.....##.########..######...##.##.##.##........##..##.....##
                    ....##......##..........##....##...##...#########.##..####.......##.##........#########.##...##...##.......##..####.##........##..#########
                    ....##......##...###....##....##....##..##.....##.##...###.##....##.##........##.....##.##....##..##.......##...###.##....##..##..##.....##
                    ..######..######.###....##....##.....##.##.....##.##....##..######..##........##.....##.##.....##.########.##....##..######..####.##.....##
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open8}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>9. OBLIGACIÓN DE REPORTE DE INFORMACIÓN ESPECÍFICA POR PARTE DE LA ENTIDAD</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu8" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title texto" style={{ cursor: "pointer" }}><b>9.1. Normatividad Especial.</b></h6>
                            <a href="/normativa">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">9.1. Normatividad Especial.</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    ....##....#######.........###.....######...######..########..######..####.########..####.##.......####.########.....###....########.
                    ..####...##.....##.......##.##...##....##.##....##.##.......##....##..##..##.....##..##..##........##..##.....##...##.##...##.....##
                    ....##..........##......##...##..##.......##.......##.......##........##..##.....##..##..##........##..##.....##..##...##..##.....##
                    ....##....#######......##.....##.##.......##.......######....######...##..########...##..##........##..##.....##.##.....##.##.....##
                    ....##...##............#########.##.......##.......##.............##..##..##.....##..##..##........##..##.....##.#########.##.....##
                    ....##...##........###.##.....##.##....##.##....##.##.......##....##..##..##.....##..##..##........##..##.....##.##.....##.##.....##
                    ..######.#########.###.##.....##..######...######..########..######..####.########..####.########.####.########..##.....##.########.
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open9}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>ATENCIÓN Y SERVICIOS A LA CIUDADANÍA</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu9" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <a href="/#contacto">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b>1. Trámites, Otros Procedimientos Administrativos y consultas de acceso a información pública.</b></h6>
                            </a>  
                            <h6 className="info-title"><b>2. Canales de atención y pida una cita.</b></h6>
                            <a href="/peticiones ">
                              <h6 className="info-title texto"><b className="subItem">2.1. Canales habilitados para la atención a la ciudadanía</b></h6>
                            </a>
                            <a href="/#contacto">
                              <h6 className="info-title texto" style={{ cursor: "pointer" }}><b className="subItem">2.2. Mecanismo para que el usuario pueda agendar una cita para atención presencial, e indicar los
                                horarios de atención en sedes físicas</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {/*
                    ....##....#######......########..########...#######..########.########..######...######..####..#######..##....##
                    ..####...##.....##.....##.....##.##.....##.##.....##....##....##.......##....##.##....##..##..##.....##.###...##
                    ....##..........##.....##.....##.##.....##.##.....##....##....##.......##.......##........##..##.....##.####..##
                    ....##....#######......########..########..##.....##....##....######...##.......##........##..##.....##.##.##.##
                    ....##..........##.....##........##...##...##.....##....##....##.......##.......##........##..##.....##.##..####
                    ....##...##.....##.###.##........##....##..##.....##....##....##.......##....##.##....##..##..##.....##.##...###
                    ..######..#######..###.##........##.....##..#######.....##....########..######...######..####..#######..##....##
                    */}
                  <Col md="4">
                    <Card style={{ backgroundColor: "#2f2963", cursor: "pointer", height: "170.78px" }} onClick={open10}>
                      <CardBody id="caja">
                        <h5 className="info" style={{ color: "white", textAlign: "center" }}><b>NOTICIAS</b></h5>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="12" id="menu10" style={{ display: "none" }} className="animate__animated ">
                    <Card>
                      <CardBody>
                        <hr style={{ width: "90%" }}></hr>
                        <Row>
                          <Col md="6">
                            <h6 className="info-title"><b>12.1. Sección de Noticias.</b></h6>
                            <a href="/#linea">
                              <h6 className="info-title texto"><b className="subItem">12.1.1. Sección de noticias.</b></h6>
                            </a>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                {/*
                  .########...#######..########..#######..##....##.########..######......#######.
                  .##.....##.##.....##....##....##.....##.###...##.##.......##....##....##.....##
                  .##.....##.##.....##....##....##.....##.####..##.##.......##.................##
                  .########..##.....##....##....##.....##.##.##.##.######....######......#######.
                  .##.....##.##.....##....##....##.....##.##..####.##.............##...........##
                  .##.....##.##.....##....##....##.....##.##...###.##.......##....##....##.....##
                  .########...#######.....##.....#######..##....##.########..######......#######.
                  */}
                <Row>
                  {/*
                    .########....###....########........##.########.########....###.....######......#######.
                    ....##......##.##...##.....##.......##.##..........##......##.##...##....##....##.....##
                    ....##.....##...##..##.....##.......##.##..........##.....##...##..##.................##
                    ....##....##.....##.########........##.######......##....##.....##..######......#######.
                    ....##....#########.##...##...##....##.##..........##....#########.......##...........##
                    ....##....##.....##.##....##..##....##.##..........##....##.....##.##....##....##.....##
                    ....##....##.....##.##.....##..######..########....##....##.....##..######......#######.
                  */}
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
      {/*
    .##.....##..#######..########.....###....##.......####..######.
    .###...###.##.....##.##.....##...##.##...##.......####.##....##
    .####.####.##.....##.##.....##..##...##..##........##..##......
    .##.###.##.##.....##.##.....##.##.....##.##.......##....######.
    .##.....##.##.....##.##.....##.#########.##..................##
    .##.....##.##.....##.##.....##.##.....##.##............##....##
    .##.....##..#######..########..##.....##.########.......######.
    */}
      {/*
    ..........########.....###....########..#######...######........###....########..####.########.########..########..#######...######.
    ..........##.....##...##.##......##....##.....##.##....##......##.##...##.....##..##..##.......##.....##....##....##.....##.##....##
    ..........##.....##..##...##.....##....##.....##.##...........##...##..##.....##..##..##.......##.....##....##....##.....##.##......
    ..........##.....##.##.....##....##....##.....##..######.....##.....##.########...##..######...########.....##....##.....##..######.
    ..........##.....##.#########....##....##.....##.......##....#########.##.....##..##..##.......##...##......##....##.....##.......##
    ..........##.....##.##.....##....##....##.....##.##....##....##.....##.##.....##..##..##.......##....##.....##....##.....##.##....##
    ..........########..##.....##....##.....#######...######.....##.....##.########..####.########.##.....##....##.....#######...######.
    */}
      <Modal isOpen={modal1} toggle={() => handleModal1(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal1(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              DATOS ABIERTOS
            </h3>
            <Row className="justify-content-center">
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Cambio De Nombre En Colombia</h4>
                    <a href="https://www.datos.gov.co/Justicia-y-Derecho/Cambios-De-Nombre-En-Colombia/wqmn-u3ud"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Legitimación De Hijos En Colombia</h4>
                    <a href="https://www.datos.gov.co/Justicia-y-Derecho/Legitimaci-n-De-Hijos-En-Colombia/w8my-uyza"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Matrimonios Civiles Entre Personas Del Mismo Sexo</h4>
                    <a href="https://www.datos.gov.co/Justicia-y-Derecho/Matrimonios-Civiles-Entre-Personas-Del-Mismo-Sexo-/xf4s-f2cq"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Cambio De Nombre En Colombia</h4>
                    <a href={require("assets/pdf/2020.xlsx")}
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal1(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########..########..########..######...##.....##.##....##.########....###.....######.....##....##....########..########..######..########..##.....##.########..######..########....###.....######.
    .##.....##.##.....##.##.......##....##..##.....##.###...##....##......##.##...##....##.....##..##.....##.....##.##.......##....##.##.....##.##.....##.##.......##....##....##......##.##...##....##
    .##.....##.##.....##.##.......##........##.....##.####..##....##.....##...##..##............####......##.....##.##.......##.......##.....##.##.....##.##.......##..........##.....##...##..##......
    .########..########..######...##...####.##.....##.##.##.##....##....##.....##..######........##.......########..######....######..########..##.....##.######....######.....##....##.....##..######.
    .##........##...##...##.......##....##..##.....##.##..####....##....#########.......##.......##.......##...##...##.............##.##........##.....##.##.............##....##....#########.......##
    .##........##....##..##.......##....##..##.....##.##...###....##....##.....##.##....##.......##.......##....##..##.......##....##.##........##.....##.##.......##....##....##....##.....##.##....##
    .##........##.....##.########..######....#######..##....##....##....##.....##..######........##.......##.....##.########..######..##.........#######..########..######.....##....##.....##..######.
    */}
      <Modal isOpen={modal2} toggle={() => handleModal2(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal2(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Preguntas frecuentes
            </h3>
            <Row>
              <Col md="6">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Superintendencia de Notariado y Registro</h4>
                    <a href="https://www.supernotariado.gov.co/portal/66.Ley%20de%20Trasparencia-66.2.Informacion%20de%20Interes/3.Boceto%20Manual%20Preguntas%20Frecuentes%20Servicio%20Notarial%20B.pdf"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Registraduría Nacional del Estado Civil</h4>
                    <a href="https://www.registraduria.gov.co/-Preguntas-Frecuentes-219-" role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal2(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    ..######...##........#######...######.....###....########..####..#######.
    .##....##..##.......##.....##.##....##...##.##...##.....##..##..##.....##
    .##........##.......##.....##.##........##...##..##.....##..##..##.....##
    .##...####.##.......##.....##..######..##.....##.########...##..##.....##
    .##....##..##.......##.....##.......##.#########.##...##....##..##.....##
    .##....##..##.......##.....##.##....##.##.....##.##....##...##..##.....##
    ..######...########..#######...######..##.....##.##.....##.####..#######.
    */}
      <Modal isOpen={modal3} toggle={() => handleModal3(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal3(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Preguntas frecuentes
            </h3>
            <Row className="justify-content-center">
              <Col md="6">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Se remite a la página 46 del documento "Cartilla de Diligencias Notariales"
                      que contiene el glosario de los términos más usados en derecho notarial:</h4>
                    <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/06/diligencias.pdf" role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <button type="button" className="btn btn-outline-light">
                      <a href={require("assets/pdf/Glosario.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                        <img
                          alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                          width="80px"
                          src={require("assets/img/Notaria/PDF.png")}
                        ></img>
                        <h6>Glosario</h6>
                      </a>
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal3(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .##.....##.####..######..####..#######..##....##
    .###...###..##..##....##..##..##.....##.###...##
    .####.####..##..##........##..##.....##.####..##
    .##.###.##..##...######...##..##.....##.##.##.##
    .##.....##..##........##..##..##.....##.##..####
    .##.....##..##..##....##..##..##.....##.##...###
    .##.....##.####..######..####..#######..##....##
    */}
      <Mision modal={modal15} setModal={() => handleModal15(false)} />
      {/*
    .########.##.....##.##....##..######..####..#######..##....##.########..######.....##....##....########..########.########..########.########..########..######.
    .##.......##.....##.###...##.##....##..##..##.....##.###...##.##.......##....##.....##..##.....##.....##.##.......##.....##.##.......##.....##.##.......##....##
    .##.......##.....##.####..##.##........##..##.....##.####..##.##.......##............####......##.....##.##.......##.....##.##.......##.....##.##.......##......
    .######...##.....##.##.##.##.##........##..##.....##.##.##.##.######....######........##.......##.....##.######...########..######...########..######....######.
    .##.......##.....##.##..####.##........##..##.....##.##..####.##.............##.......##.......##.....##.##.......##.....##.##.......##...##...##.............##
    .##.......##.....##.##...###.##....##..##..##.....##.##...###.##.......##....##.......##.......##.....##.##.......##.....##.##.......##....##..##.......##....##
    .##........#######..##....##..######..####..#######..##....##.########..######........##.......########..########.########..########.##.....##.########..######.
    */}
      <Funciones modal={modal4} setModal={() => handleModal4(false)} />
      {/*
    ..#######..########...######......###....##....##.####..######...########.....###....##.....##....###...
    .##.....##.##.....##.##....##....##.##...###...##..##..##....##..##.....##...##.##...###...###...##.##..
    .##.....##.##.....##.##.........##...##..####..##..##..##........##.....##..##...##..####.####..##...##.
    .##.....##.########..##...####.##.....##.##.##.##..##..##...####.########..##.....##.##.###.##.##.....##
    .##.....##.##...##...##....##..#########.##..####..##..##....##..##...##...#########.##.....##.#########
    .##.....##.##....##..##....##..##.....##.##...###..##..##....##..##....##..##.....##.##.....##.##.....##
    ..#######..##.....##..######...##.....##.##....##.####..######...##.....##.##.....##.##.....##.##.....##
    */}
      <Organigrama modal={modal5} setModal={() => handleModal5(false)} />
      {/*
    ....###.....######...########..########.##.....##.####....###.....######..####..#######..##....##.########..######.
    ...##.##...##....##..##.....##.##.......###...###..##....##.##...##....##..##..##.....##.###...##.##.......##....##
    ..##...##..##........##.....##.##.......####.####..##...##...##..##........##..##.....##.####..##.##.......##......
    .##.....##.##...####.########..######...##.###.##..##..##.....##.##........##..##.....##.##.##.##.######....######.
    .#########.##....##..##...##...##.......##.....##..##..#########.##........##..##.....##.##..####.##.............##
    .##.....##.##....##..##....##..##.......##.....##..##..##.....##.##....##..##..##.....##.##...###.##.......##....##
    .##.....##..######...##.....##.########.##.....##.####.##.....##..######..####..#######..##....##.########..######.
    */}
      <Modal isOpen={modal6} toggle={() => handleModal6(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal6(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Directorio de agremiaciones, asociaciones y otros grupos de interés
            </h3>
            <Row className="justify-content-center">
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Supernotariado</h4>
                    <a href="https://www.supernotariado.gov.co/"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">UCNC</h4>
                    <a href="https://www.ucnc.com.co/sitio/"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Registraduría</h4>
                    <a href="https://www.registraduria.gov.co/"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">UNCVALLE</h4>
                    <a href="http://www.uncvalle.com/"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Certicamara</h4>
                    <a href="https://web.certicamara.com/"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card style={{ padding: "15px 15px 15px 15px" }}>
                  <CardBody className="text-center" style={{ border: "2px solid #d0d0d0" }}>
                    <h4 className="text-center">Ciegos y sordos</h4>
                    <a href="https://www.ciegosysordos.org.co/"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal6(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .####.##....##.########..#######..########..##.....##.########..######.....########..########.....######...########..######..########.####.##....##
    ..##..###...##.##.......##.....##.##.....##.###...###.##.......##....##....##.....##.##..........##....##..##.......##....##....##.....##..###...##
    ..##..####..##.##.......##.....##.##.....##.####.####.##.......##..........##.....##.##..........##........##.......##..........##.....##..####..##
    ..##..##.##.##.######...##.....##.########..##.###.##.######....######.....##.....##.######......##...####.######....######.....##.....##..##.##.##
    ..##..##..####.##.......##.....##.##...##...##.....##.##.............##....##.....##.##..........##....##..##.............##....##.....##..##..####
    ..##..##...###.##.......##.....##.##....##..##.....##.##.......##....##....##.....##.##..........##....##..##.......##....##....##.....##..##...###
    .####.##....##.##........#######..##.....##.##.....##.########..######.....########..########.....######...########..######.....##....####.##....##
    */}
      <Modal isOpen={modal7} toggle={() => handleModal7(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal7(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Informes de gestión, evaluación y auditoría
            </h3>
            <Row className="justify-content-center">
              <Col md="10">
                <h4 className="text-justify">
                  "Los informes que presentan los Señores Notarios a la Superintendencia de Notariado y Registro son:<br></br>
                  <br></br>
                  Informe Estadistico Notarial, cuya información financiera y contable es reservada. Artículo 61
                  del Código de Comercio. El informe estadistico que remiten mensualmente las Notarías a la SNR a
                  través de los correos habilitados para ello, según la Instrucción Administrativa 4 de 2019.<br></br>
                  <br></br>
                  Los informes que rinden los Señores Notarios a la UIAF son:<br></br>
                  <br></br>
                  Reporte de Operación Notarial (RON).<br></br>
                  Reporte de Operaciones intentadas y operaciones sospechosas ROS.<br></br>
                  Reporte de ausencia de operaciones sospechosos<br></br>
                  Reporte de transacciones en efectivo.<br></br>
                  Reporte de ausencia de transacciones en efectivo.<br></br>
                  Reporte de adjudicaciones en remate.<br></br>
                  <br></br>
                  Los informes que allegan los Señores Notarios a la DIAN son:<br></br>
                  <br></br>
                  Información exogena."
                </h4>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal7(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.##....##.########.########..######......######...#######..##....##.########.########...#######..##......
    .##.......###...##....##....##.......##....##....##....##.##.....##.###...##....##....##.....##.##.....##.##......
    .##.......####..##....##....##.......##..........##.......##.....##.####..##....##....##.....##.##.....##.##......
    .######...##.##.##....##....######....######.....##.......##.....##.##.##.##....##....########..##.....##.##......
    .##.......##..####....##....##.............##....##.......##.....##.##..####....##....##...##...##.....##.##......
    .##.......##...###....##....##.......##....##....##....##.##.....##.##...###....##....##....##..##.....##.##......
    .########.##....##....##....########..######......######...#######..##....##....##....##.....##..#######..########
    */}
      <Modal isOpen={modal8} toggle={() => handleModal8(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal8(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Entes de control que vigilan a la entidad y mecanismos de supervisión
            </h3>
            <Row className="justify-content-center">
              <Col md="10">
                <h4 className="text-justify">
                  La orientación, inspección, vigilancia y control a las Notarías lo ejerce la Superintendencia de Notariado
                  y Registro en virtud del artículo 4 del Decreto 2723 de 2014.
                  <br></br>
                  <br></br>
                  La Superintendencia de Notariado y Registro ejerce el control conforme a los informes estadisticos
                  que rinden mensualmente los Notarios, así como con ocasión de las visitas previstas en
                  los artículos 2.2.6.1.6.3.1 y siguientes del Decreto 1069 de 2015.
                  <br></br>
                  <br></br>
                  El control que ejerce la Superintendencia de Notariado y Registro sobre las Notarias es de
                  naturaleza administrativa y disciplinaria.
                  <br></br>
                  <br></br>
                  Sede Principal y Atención al Ciudadano
                  <br></br>
                  Calle 26 No. 13-49 Interior 201, Bogotá D.C. Colombia.
                  <br></br>
                  Código postal : # 110311 – 110311000
                  <br></br>
                  Nit: 899.999.007-0
                  <br></br>
                  Horario de Atención : 08:00 a.m a 05:00 p.m.
                  <br></br>
                  Telefono – Conmutador :  57+(601) 514 0313
                  <br></br>
                  Conmutador Atención al Ciudadano :  57+(601) 514 0313
                  <br></br>
                  Correo Institucional : correspondencia@supernotariado.gov.co
                  <br></br>
                  Sitio Web : https://www.supernotariado.gov.co/                  
                </h4>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal8(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.########.....###....##.....##.####.########.########..######.
    ....##....##.....##...##.##...###...###..##.....##....##.......##....##
    ....##....##.....##..##...##..####.####..##.....##....##.......##......
    ....##....########..##.....##.##.###.##..##.....##....######....######.
    ....##....##...##...#########.##.....##..##.....##....##.............##
    ....##....##....##..##.....##.##.....##..##.....##....##.......##....##
    ....##....##.....##.##.....##.##.....##.####....##....########..######.
    */}
      <Modal isOpen={modal9} toggle={() => handleModal9Cerrar(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal9Cerrar(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Trámites y servicios
            </h3>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Los trámites que adelantan las Notarías son los siguientes:
            </h3>
            <Row className="justify-content-center">
              <Col md="12">
                <Card>
                  <CardBody>
                    <h4>
                      <ol>
                        <li>Recibir, extender y autorizar las declaraciones que conforme a las Leyes requieran escritura
                          pública y aquellas a las cuales los interesados quieran revestir de esta solemnidad.
                        </li>
                        <li>Autorizar el reconocimiento espontáneo de documentos privados. </li>
                        <li>Dar testimonio de la autenticidad de firmas de funcionarios o particulares y de otros Notarios que
                          las tengan registradas ante ellos.
                        </li>
                        <li>Dar fe de la correspondencia o identidad que exista entre un documento que tenga a la vista y su
                          copia mecánica o literal.
                        </li>
                        <li>Acreditar la existencia de las personas naturales y expedir la correspondiente fe de vida.</li>
                        <li>Recibir y guardar dentro del protocolo los documentos o actuaciones que la Ley o el Juez ordenen
                          protocolizar o que los interesados quieran proteger de esta manera.
                        </li>
                        <li>Expedir copias o certificaciones según el caso, de los documentos que reposen en sus archivos.</li>
                        <li>Dar testimonio escrito con fines jurídico - probatorios de los hechos percibidos por ellos dentro del ejercicio de
                          sus Tramites y de que no haya quedado dato formal en sus archivos.
                        </li>
                        <li>Intervenir en el otorgamiento, extensión y autorización de los testamentos solemnes que conforme a la
                          Ley civil deban otorgarse ante ellos.
                        </li>
                        <li>Practicar apertura y publicación de los testamentos cerrados.</li>
                        <li>Llevar el registro del estado civil de las personas en los casos, por los sistemas y con las formalidades prescritos en la Ley.</li>
                        <li>Las demás Tramites que les señalen las Leyes. </li>
                      </ol>
                    </h4>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center" style={{ marginBottom: "45px" }}>Normatividad</h4>
                    <a href="/normativa"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">Protocolos de atención</h4>
                    <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"
                      style={{ marginBottom: "10px" }}
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">Formatos y Formularios</h4>
                    <a href="/formato"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">Servicios</h4>
                    <a href="/servicios" role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">Tarifas</h4>
                    <a href="/tarifa" role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                      <b style={{ fontSize: "16px" }}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal9Cerrar(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .####.##....##.########..#######..########..##.....##.########....########..########....########..########.########.####..######..####..#######..##....##.########..######.
    ..##..###...##.##.......##.....##.##.....##.###...###.##..........##.....##.##..........##.....##.##..........##.....##..##....##..##..##.....##.###...##.##.......##....##
    ..##..####..##.##.......##.....##.##.....##.####.####.##..........##.....##.##..........##.....##.##..........##.....##..##........##..##.....##.####..##.##.......##......
    ..##..##.##.##.######...##.....##.########..##.###.##.######......##.....##.######......########..######......##.....##..##........##..##.....##.##.##.##.######....######.
    ..##..##..####.##.......##.....##.##...##...##.....##.##..........##.....##.##..........##........##..........##.....##..##........##..##.....##.##..####.##.............##
    ..##..##...###.##.......##.....##.##....##..##.....##.##..........##.....##.##..........##........##..........##.....##..##....##..##..##.....##.##...###.##.......##....##
    .####.##....##.##........#######..##.....##.##.....##.########....########..########....##........########....##....####..######..####..#######..##....##.########..######.
    */}
      <Modal isOpen={modal11} toggle={() => handleModal11(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal11(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              INFORME DE PETICIONES, QUEJAS, RECLAMOS, DENUNCIAS Y SOLICITUDES DE ACCESO A LA INFORMACIÓN
            </h3>
            <center>
              <a href={require("../../assets/pdf/10. 10 PROCEDIMIENTO DE MANEJO DE LAS PETICIONES, QUEJAS, RECLAMOS Y SOLICITUDES DE ACCESO A LA INFORMACION.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                <img
                  alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                  width="80px"
                  src={require("assets/img/Notaria/PDF.png")}
                ></img>
                <h6>PROCEDIMIENTO DE MANEJO DE LAS PETICIONES, <br></br>QUEJAS, RECLAMOS Y SOLICITUDES DE <br></br>ACCESO A LA INFORMACION</h6>
              </a>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal11(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal12} toggle={() => handleModal12(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header" style={{ justifyContent: 'center' }}>
            <h3 className="title" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Página en construcción
            </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal12(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <center>
              <img
                alt="Obrero animado diciendo que la pagina se encuentra en construcción"
                width="80%"
                src={require("assets/img/Notaria/pag.png")}
              ></img>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal12(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########.########.##....##.####..######...#######...######.
    .##..........##....###...##..##..##....##.##.....##.##....##
    .##..........##....####..##..##..##.......##.....##.##......
    .######......##....##.##.##..##..##.......##.....##..######.
    .##..........##....##..####..##..##.......##.....##.......##
    .##..........##....##...###..##..##....##.##.....##.##....##
    .########....##....##....##.####..######...#######...######.
    */}
      <Modal isOpen={modal13} toggle={() => handleModal13(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal13(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Formato alternativo para grupos étnicos y culturales
            </h3>
            <Row className="justify-content-center">
              <Col md="2">
                <center>
                  <a href={require("assets/pdf/FORMULARIO TRADUCCION ESPAÑOL MOI.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                    <img
                      alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                      width="80px"
                      src={require("assets/img/Notaria/PDF.png")}
                    ></img>
                    <h6>FORMULARIO TRADUCCION ESPAÑOL MOI</h6>
                  </a>
                </center>
              </Col>
            </Row>
            <div>
              <center>
                <iframe width="453" height="240" src="https://www.youtube.com/embed/V-nb0Y5haos" title="Video Youtubr Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </center>
            </div>
            <div>
              <center>
                <iframe width="453" height="240" src="https://www.youtube.com/embed/zoXVd3oOZs4" title="Video Youtubr Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </center>
            </div>
            <div>
              <center>
                <iframe width="453" height="240" src="https://www.youtube.com/embed/3netCiMR0JI" title="Video Youtubr Player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </center>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal13(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      {/*
    .########..####..######...######.....###....########.....###.....######..####.########.....###....########.
    .##.....##..##..##....##.##....##...##.##...##.....##...##.##...##....##..##..##.....##...##.##...##.....##
    .##.....##..##..##.......##........##...##..##.....##..##...##..##........##..##.....##..##...##..##.....##
    .##.....##..##...######..##.......##.....##.########..##.....##.##........##..##.....##.##.....##.##.....##
    .##.....##..##........##.##.......#########.##........#########.##........##..##.....##.#########.##.....##
    .##.....##..##..##....##.##....##.##.....##.##........##.....##.##....##..##..##.....##.##.....##.##.....##
    .########..####..######...######..##.....##.##........##.....##..######..####.########..##.....##.########.
    */}
      <Modal isOpen={modal14} toggle={() => handleModal14(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal14(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Accesibilidad en medios electrónicos para la población en situación de discapacidad
            </h3>
            <center>
              <img
                alt="Obrero animado diciendo que la pagina se encuentra en construcción"
                width="80%"
                src={require("assets/img/Notaria/pag.png")}
              ></img>
            </center>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => setModal14(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal16} toggle={() => setModal16(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal16(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Información para poblacion vulnerable
            </h3>

            •	Por medio del cual se establece un régimen especial y excepcional para adquirir la nacionalidad colombiana por nacimiento, para hijos e hijas de venezolanos en situación de migración regular o irregular, o de solicitantes de refugio, nacidos en territorio colombiano, con el fin de prevenir la apátrida.

            <br /><a href="http://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/30038068">http://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Leyes/30038068</a>

            <br /><b>•	Mujeres cabeza de familia:</b> declaración extraproceso gratis.
            <br /><b>•	Comunidades indígenas:</b> escritura pública gratuita para  cambio de nombre y  para la corrección de errores u omisiones en el registro civil.
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal16(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal17} toggle={() => handleModal17(true)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal17(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              Directorio de entidades
            </h3>
            <Card style={{ borderRadius: "10px" }}>
              <CardBody className="text-center">
                <h4 className="text-center" style={{ marginBottom: "45px" }}>Ministerio de Justicia y del Derecho</h4>
                <a href="https://www.minjusticia.gov.co/"
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ir a página</b>
                </a>
              </CardBody>
            </Card>
            <Card style={{ borderRadius: "10px" }}>
              <CardBody className="text-center">
                <h4 className="text-center" style={{ marginBottom: "45px" }}>Superintendencia de notariado y registro</h4>
                <a href="https://www.supernotariado.gov.co/"
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ir a página</b>
                </a>
              </CardBody>
            </Card>
            <Card style={{ borderRadius: "10px" }}>
              <CardBody className="text-center">
                <h4 className="text-center" style={{ marginBottom: "45px" }}>Registraduría Nacional de Estado Civil</h4>
                <a href="https://www.registraduria.gov.co/"
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ir a página</b>
                </a>
              </CardBody>
            </Card>
            <Card style={{ borderRadius: "10px" }}>
              <CardBody className="text-center">
                <h4 className="text-center" style={{ marginBottom: "45px" }}>Procuraduría General de la Nación</h4>
                <a href="https://www.procuraduria.gov.co/portal/"
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ir a página</b>
                </a>
              </CardBody>
            </Card>
            <Card style={{ borderRadius: "10px" }}>
              <CardBody className="text-center">
                <h4 className="text-center" style={{ marginBottom: "45px" }}>Ventanilla Única de Registro VUR</h4>
                <a href="https://www.vur.gov.co/"
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ir a página</b>
                </a>
              </CardBody>
            </Card>
            <Card style={{ borderRadius: "10px" }}>
              <CardBody className="text-center">
                <h4 className="text-center" style={{ marginBottom: "45px" }}>Suin Juriscol</h4>
                <a href="https://www.suin-juriscol.gov.co/"
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ir a página</b>
                </a>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal17(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal18} toggle={() => handleModal18(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal18(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalFooter>
            <Button
              color="primary"
              type="button"
              onClick={() => handleModal18(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal10} toggle={() => setModal10(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal10(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              CONTENIDO DE TODA DECISION Y/O POLITICA QUE HAYA ADOPTADO Y AFECTE AL PUBLICO
            </h3>
            <Card>
              <CardBody className="text-center">
                <h4 className="text-center">PROTOCOLO DE SERVICIO AL CLIENTE</h4>
                <a href="https://www.ucnc.com.co/sitio/wp-content/uploads/2020/09/2020-08-21-PROTOCOLO-DE-BIOSEGURIDAD-PARA-NOTARIAS-UCNC-Version-Final-Anexo-SNR.pdf"
                  style={{ marginBottom: "10px" }}
                  role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                  <b style={{ fontSize: "16px" }}>Ver información</b>
                </a>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="button"
              onClick={() => setModal10(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal10} toggle={() => setModal10(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => setModal10(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              CONTENIDO DE TODA DECISION Y/O POLITICA QUE HAYA ADOPTADO Y AFECTE AL PUBLICO
            </h3>
            <Card>
              <CardBody className="text-center">
                <h4 className="text-center">PROTOCOLO DE SERVICIO AL CLIENTE</h4>
                <center>
                  <a href={require("assets/pdf/Informacion_adicional.pdf")} style={{ textDecoration: "none !important" }} target="_black">
                    <img
                      alt="Imagen con referencia hacia un icono para descargar un archivo en PDF"
                      width="80px"
                      src={require("assets/img/Notaria/PDF.png")}
                    ></img>
                    <h6>Ver Protocolos</h6>
                  </a>
                </center>
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="button"
              onClick={() => setModal10(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
      <Modal isOpen={modal19} toggle={() => handleModal19(false)} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{ backgroundColor: "White", borderRadius: "15px" }}>
          <div className="modal-header">
            <h3> </h3>
            <button
              aria-label="Close"
              className="close"
              type="button"
              onClick={() => handleModal19(false)}
            >
              <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
            </button>
          </div>
          <ModalBody>
            <h3 className="title text-center" style={{ color: "#2c2c2c" }} id="exampleModalLiveLabel">
              CALENDARIO ACTIVIDADES
            </h3>
          </ModalBody>
          <ModalFooter>
            <Button
              color="default"
              type="button"
              onClick={() => handleModal19(false)}
              className="text-center"
            >
              <b style={{ fontSize: "17px" }}>Cerrar</b>
            </Button>
          </ModalFooter>
        </div>
      </Modal>
    </>
  );
}

export default Transpa;
