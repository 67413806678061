import {USER_LOADED, ROLE_LOADED} from '../types';



const initialState = {
    token: localStorage.getItem('token-auth'),
    isAuthenticated: null,
    isLoading: true,
    user: null,
    rolActivo: localStorage.getItem('role')
}

export default function(state = initialState, action){
    
    switch(action.type){
        
        case USER_LOADED:
            return{
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: {
                    nombres:action.payload.nombres,
                    apellidos: action.payload.apellidos,
                    correo:  action.payload.correo,
                    genero:  action.payload.genero,
                    roles: action.payload.roles,
                    foto: action.payload.foto
                },
                token: action.payload.token               
            }
        
        case ROLE_LOADED:
            return{
                ...state,    
                user:[...state.user.roles, action.payload]
            }


        case 'USER_LOADING':
            return{
                ...state,
                isLoading: true                
            }

        case 'USERTOKEN_LOADED':
            
            return{
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: {
                    nombres:action.payload.nombres,
                    apellidos: action.payload.apellidos,
                    correo:  action.payload.correo,
                    genero:  action.payload.genero,
                    roles: action.payload.roles,
                    foto: action.payload.foto                   
                }   ,
                token:  action.payload.token              
            }
            
        case 'AUTH-ERROR':
            return{
                ...state,    
                token: null,            
                isAuthenticated: null,
                isLoading: false,
                user: null,
                rolActivo: null
            }

        

        
        case 'ROL_ACTIVO':
        return{
            ...state,    
            rolActivo: action.payload
        }

        default: 
            return state
    }
}

