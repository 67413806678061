import React from "react";

// reactstrap components
import {  Modal,
          ModalBody,
          ModalFooter,
          Button
          
} from "reactstrap";

// core components

function Mision(props) {

  return (
    <>
      <Modal isOpen={props.modal} toggle={props.setModal} className="modal-lg modal-dialog modal-dialog-centered">
        <div style={{backgroundColor: "White" ,borderRadius: "15px"}}>
            <div className="modal-header">
                <h3> </h3>
                <button
                aria-label="Close"
                className="close"
                type="button"
                onClick={props.setModal}
                >
                <span aria-hidden={true}><i className="now-ui-icons ui-1_simple-remove"></i></span>
                </button>
            </div>
            <ModalBody>
             
                <h3 className="text-center title">
                    <b style={{color : "#bd0b0ed8"}}>Misión</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>Ofrecer servicios notariales de excelencia y calidad, con convicción de certeza y seguridad jurídica a nuestros clientes en su persona y bienes, generando continuamente acciones que fortalezcan la confianza por nuestros servicios proporcionados.</b>
                </h5>
                <h3 className="text-center title">
                    <b style={{color : "#bd0b0ed8"}}>Visión</b><br></br>
                </h3>
                <h5 className="description text-justify" style={{color: "Black"}}>
                    <b>La Notaría Segunda del Círculo de Floridablanca, será reconocida por la excelencia en el servicio a sus clientes, la sistematización de sus procesos y el enfoque en la transformación digital del servicio notarial, contando con un equipo humano ético, calificado y comprometido con un servicio amable y de calidad.</b>
                </h5>
                
                
            </ModalBody>
            <ModalFooter>
              <Button
                  color="default"
                  type="button"
                  onClick={props.setModal}
                  className="text-center"
              >
                  <b style={{fontSize: "17px"}}>Cerrar</b>
              </Button>
            </ModalFooter>
        </div>
    </Modal>
    </>
  );
}

export default Mision;
