import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Procesos/Procesos.js";
import PresentationHeader from "components/Headers/PresentationHeader2.js";

function PoliticaTratamientoDatos() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Politica Tratamientos Datos"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row>
              <Col md="12">
                <Card style={{marginTop:"2%"}}>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="2">
                        <center>
                          <a href={require("../assets/pdf/politica_seguridad.pdf")} style={{textDecoration: "none !important"}} target="_black">
                            <img
                              alt="PDF Politica Tratamientos"
                              width="80px"
                              src={require("assets/img/Notaria/PDF.png")}
                            ></img>
                            <h6>Politica Tratamiento Datos</h6>
                          </a>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default PoliticaTratamientoDatos;