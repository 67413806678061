import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Procesos/Procesos.js";
import PresentationHeader from "components/Headers/PresentationHeader2.js";

function MisionVision() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Mision Y Vision"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row>
              <Col md="12">
                <Card style={{marginTop:"2%"}}>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="12">
                        <center>
                          <h3 className="text-center title">
                            <b style={{color : "#bd0b0ed8"}}>Misión</b><br></br>
                          </h3>
                          <h5 className="description text-justify" style={{color: "Black"}}>
                            <b>Ofrecer servicios notariales de excelencia y calidad, con convicción de certeza y seguridad jurídica a nuestros clientes en su persona y bienes, generando continuamente acciones que fortalezcan la confianza por nuestros servicios proporcionados.</b>
                          </h5>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody>
                    <Row className="justify-content-center">
                      <Col md="12">
                        <center>
                          <h3 className="text-center title">
                              <b style={{color : "#bd0b0ed8"}}>Visión</b><br></br>
                          </h3>
                          <h5 className="description text-justify" style={{color: "Black"}}>
                              <b>La Notaría Segunda del Círculo de Floridablanca, será reconocida por la excelencia en el servicio a sus clientes, la sistematización de sus procesos y el enfoque en la transformación digital del servicio notarial, contando con un equipo humano ético, calificado y comprometido con un servicio amable y de calidad.</b>
                          </h5>
                        </center>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default MisionVision;