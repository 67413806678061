import React from "react";
// javascript library that creates a parrallax effect
import Rellax from "rellax";
// reactstrap components
import { Container, 
    Row, 
    Col,
    Card,
    CardBody
} from "reactstrap";
// core components

// sections for this page
// import Politica from "./Normativa/Normativa.js";
import PresentationHeader from "components/Headers/PresentationHeader2.js";


function Normativa() {

  // const [tooltipOpen, setTooltipOpen] = useState(false);
  // const toggle = () => setTooltipOpen(!tooltipOpen);
  
  React.useEffect(() => {
    document.body.classList.add("presentation-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    // initialise Rellax for this page
    if (window.innerWidth >= 991) {
      setTimeout(function () {
        new Rellax(".rellax", {
          center: true,
        });
      }, 5000);
      new Rellax(".rellax-header");
      new Rellax(".rellax-text");
    }

    // we need to add a script for the github buttons
    let script = document.createElement("script");
    script.src = "https://buttons.github.io/buttons.js";
    script.id = "github-buttons-script-id";
    document.body.appendChild(script);

    return function cleanup() {
      document.body.classList.remove("tramites-page");
      document.body.classList.remove("sidebar-collapse");

      // we need to remove the script when we change the page
      script.parentNode.removeChild(script);
    };
  });
  return (
    <>
      <div className="wrapper">
        <PresentationHeader titulo="Normativa que regula la entidad"/>
        <div id= "servicio" style={{backgroundColor: "#f9f9f9"}}>
          <Container className="fluid">
            <Row>
              <Col md="6" style={{marginTop:"2%"}}>
                <a href="https://www.suin-juriscol.gov.co/">
                  <h6 className="info-title texto"><b>Enlace a Sistema Único de Información Normativa – SUIN.</b></h6>
                </a>
              </Col>
            </Row>
            <Row>
              <Col md="4" style={{marginTop:"2%"}}>
                <Card >
                  <CardBody className="text-center">
                    <h4 className="text-center">ARTICULO 131 DE LA CONSTITUCION NACIONAL</h4>
                    <a href="https://www.constitucioncolombia.com/titulo-5/capitulo-2/articulo-131" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" style={{marginTop:"2%"}}>
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">DECRETO DE CREACION DE LA NOTARIA</h4>
                    <a href="http://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Decretos/1889944" 
                        role="button" className="btn btn-azul"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4" style={{marginTop:"2%"}}>
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">DECRETO 0960 DE 1970: ESTATUTO NOTARIAL</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_0960_1970.html" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">DECRETO REGLAMENTARIO 2148 DE 1983: POR EL CUAL SE REGLAMENTE EL DECRETO 0960 DE 1970</h4>
                    <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma.php?i=5227" 
                        role="button" className="btn btn-azul"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">DECRETO LEY 1260 DE 1970: POR EL CUAL SE EXPIDE EL REGISTRO   DEL ESTADO CIVIL DE LAS PERSONAS</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/decreto_1260_1970.html"
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">POR EL CUAL SE EXPIDE EL ESTATUTO DE REGISTRO DE INSTRUMENTOS PUBLICOS Y DICTAN OTRAS NORMAS</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1579_2012.html" 
                        role="button" className="btn btn-azul"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">DECRETO 1000 DE 2015 DEL MINISTERIO DE JUSTICIA Y DERECHO</h4>
                    <a href="http://www.suin-juriscol.gov.co/viewDocument.asp?ruta=Decretos/30019809" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h5 className="text-center" style={{marginTop: "22px"}}>RÉGIMEN PARA EL EJERCICIO DE LA CAPACIDAD LEGAL DE LAS PERSONAS CON DISCAPACIDAD MAYORES DE EDAD</h5>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1996_2019.html"
                        role="button" className="btn btn-azul"  target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">POR MEDIO  DE LA CUALSE EXPIDE EL CODIGO GENERAL DEL PROCESO</h4>
                    <a href="http://www.secretariasenado.gov.co/senado/basedoc/ley_1564_2012.html" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="8">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">RESOLUCIÓN 1299 DE 2020 “POR LA CUAL SE ACTUALIZAN LAS TARIFAS DE LOS DERECHOS POR CONCEPTO DEL EJERCICIO DE LA FUNCIÓN NOTARIAL. SUPERINTENDENCIA DE NOTARIADO Y REGISTRO</h4>
                    <a href="https://sidn.ramajudicial.gov.co/SIDN//NORMATIVA/TEXTOS_COMPLETOS/8_RESOLUCIONES/RESOLUCIONES%202020/SNR%20Resoluci%C3%B3n%201299%20de%202020%20(Incremento%20a%20las%20tarifas%20notariales).pdf" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card>
                  <CardBody className="text-center">
                    <h4 className="text-center">RESOLUCIÓN 1204 DE 2020 DE LA REGISTRADURÍA NACIONAL DEL ESTADO CIVIL </h4>
                    <a href="https://www.registraduria.gov.co/IMG/pdf/resolucion_1204_05_02_2020_tarifas.pdf" 
                      role="button" className="btn btn-azul" target="_black" aria-disabled="true">
                        <b style={{fontSize: "16px"}}>Ver información</b>
                    </a>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Normativa;